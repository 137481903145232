import * as React from 'react'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {Divider} from '../../../../../divider'
import s from './filter-container.scss'

export const FilterContainer: React.FC = ({children}) => {
  const {isMobile} = useEnvironment()

  return (
    <div className={isMobile ? s.container : s.desktopContainer}>
      {children}
      {isMobile ? <Divider /> : null}
    </div>
  )
}
