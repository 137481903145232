import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import cn from '../../../classnames.scss'
import {InvoiceBreakdown, InvoiceBreakdownSize} from '../../../invoice-breakdown'
import {TableRow, TableRowType} from '../../../table-row'
import {Divider} from '../../common/divider'
import {OrderSuccessProps} from '../index'
import {getInvoiceItemsWithTicketInfo} from '../../../../selectors/order-success'
import s from './desktop.scss'
import {Item} from './item'
import {OrderWithSeating} from './order-with-seating'

export const Desktop = ({
  order,
  formattedOrderCreatedDate,
  hasSeatingPlan,
  t,
  navigateToMainPage,
}: OrderSuccessProps) => {
  const {
    orderNumber,
    invoice: {items, discount},
    tickets,
  } = order
  const itemsWithTicketInfo = getInvoiceItemsWithTicketInfo(items, tickets)

  const columns = hasSeatingPlan
    ? [
        {content: t('ticketsThankYou.ticketName')},
        {content: t('orderSuccess_seatInfo')},
        {content: ''},
        {content: t('ticketsThankYou.price')},
      ]
    : [
        {content: t('ticketsThankYou.ticketName')},
        {content: t('ticketsThankYou.price')},
        {content: t('ticketsThankYou.quantity')},
        {content: t('ticketsThankYou.subTotal')},
      ]

  const config = {
    dataHook: DH.INVOICE_TITLES,
    itemKey: DH.INVOICE_TITLES,
    type: TableRowType.header,
    addDivider: true,
    columns,
  }
  return (
    <div className={classNames(cn.formLabelsColor, cn.evTextFont)}>
      <div className={s.orderInformation}>
        <div className={s.orderNumber} data-hook={DH.ORDER_NUMBER}>
          {t('ticketsThankYou.orderNo', {orderNumber})}
        </div>
        <div className={s.placedOn} data-hook={DH.PLACED_ON}>
          {t('ticketsThankYou.placedOn', {date: formattedOrderCreatedDate})}
        </div>
      </div>
      {hasSeatingPlan ? (
        <OrderWithSeating order={order} />
      ) : (
        <>
          <Divider />
          <div role="table">
            <div role="rowgroup">
              <TableRow config={config} />
              <div className={s.tickets} role="rowgroup">
                {itemsWithTicketInfo.map((item, index) => (
                  <Item
                    hasSeatingPlan={hasSeatingPlan}
                    key={item.id}
                    item={item}
                    discount={discount}
                    t={t}
                    last={items.length === index + 1}
                  />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      <Divider />
      <InvoiceBreakdown containerClass={s.summaryDetails} size={InvoiceBreakdownSize.big} />
      <Divider />
      <div className={s.actions}>
        <button className={s.link} data-hook={DH.BACK_TO_SITE} onClick={() => navigateToMainPage()}>
          {t('backToSite')}
        </button>
      </div>
    </div>
  )
}
